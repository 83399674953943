.navbar {
  background-color: #333;
  color: white;
}

.navbar-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
}

.navbar-toggle {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1.5rem;
}

.navbar-links {
  position: fixed;
  top: 0;
  right: -250px;
  height: 100vh;
  width: 250px;
  background-color: #333;
  z-index: 999;
  transition: 0.3s;
}

.navbar-links.open {
  right: 0;
}

.navbar-links ul {
  list-style-type: none;
  padding: 0;
}

.navbar-links li {
  padding: 10px 0;
  text-align: center;
}

.navbar-links a {
  color: white;
  text-decoration: none;
}

.patient-detail-section {
  padding: 7px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 4px;
}

.section-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.0rem;
}

.section-divider {
  border: 0;
  height: 1px;
  background: #e0e0e0;
  margin: 0.5rem 0;
}

.section-content {
  font-size: 1.1rem;
  font-weight: 400;
  color: #555;
}


/* 
    .wrapper {
      margin-bottom: 0;
      padding-bottom: 0;

    }

    .item .purchase a {
      color: #333;
      border-color: #1C52A1;
    }

    .btn-primary {
      color: #fff;
      background-color: #1C52A1;
      border-color: #1C52A1;
    }

    .btn-primary:hover {
      color: #fff;
      background-color: #1C52A1;
      border-color: #1C52A1;
    }

    .actionlink {
      padding: 0;
      border: none;
      display: inline-flex;
      height: 51px;
      width: 135px;
      align-items: center;
      background: #1C52A1;
      cursor: pointer;
      position: relative;
      padding-left: 41px;
      color: #fff;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      font-weight: 400;
    }

    .actionlink:active,
    .actionlink:focus {
      color: white;
    }

    .steps ul li:active a {
      display: inline-block;
      width: 100%;

      background: #1C52A1;
      border-radius: 3.5px;
    }

    .buttonload {
      background-color: #04AA6D;
      border: none;
      color: white;
      font-size: 16px;
    }

    .fa {
      margin-left: -12px;
      margin-right: 8px;
    }

    .back-button-container {
      margin-right: 15px;
    }

    #backButton {
      color: gray;
      background-color: #f2f8ff;
      transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
    }


    .center-icon {
      display: flex;
      justify-content: center;
      align-items: center;

    }

    .navbar-toggler:focus {
      outline: none; border: none;box-shadow: none;
    }

    .graph-icon2 {
      position: absolute;
      top: -82px;
      left: -17px;
      width: 385px;
      height: 289px;
      z-index: 1;
    }

    .container {
      height: 100%;

    }

    .lateral-right {
      position: fixed;
      top: 0;
      right: -100%; bottom: 0;
      width: 50%; background: rgba(0, 175, 239, 0.9); z-index: 1000;transition: right 0.5s ease-in-out;
    }

    .lateral-right.open {
      right: 0;
    }

    .top-bar {

      color: #fff;
      padding: 10px;
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .close-btn {

      background: none;
      border: none;
      color: #fff;
      font-size: 20px;
      cursor: pointer;
    }

    .close-btn-container {
      margin-left: auto;
    }

    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 999; display: none;transition: opacity 0.5s ease-in-out 0.2s;
    }

    .overlay.show {
      opacity: 1;
    }

    .loader {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background-color: rgba(255, 255, 255, 0.5);
      display: none;z-index: 9999;
    }

    .loader::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 40px;
      height: 40px;
      margin: -20px; border: 3px solid #3498db;border-radius: 50%;
      border-top-color: transparent; animation: spin 1s linear infinite;
    }

    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    } */
