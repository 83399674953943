.patient-details {
  display: flex;
  flex-direction: column;
}

.patient-info {
  display: flex;
  margin-bottom: 10px;
}

.info-label {
  font-weight: bold;
  margin-right: 10px;
}

.info-value {
  flex-grow: 1;
}


.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 9999;
  display: flex;
  flex-direction: column; /* Align items in a column */
  justify-content: center;
  align-items: center;
  padding: 20px; /* Increase padding for spacing */
  color: #ffffff; /* Text color */
  font-size: 20px; /* Text font size */
}

.loading-text {
  margin-bottom: 20px; /* Add margin below the text */
}

.loader-bar {
  height: 4px; /* Adjust the height as needed */
  width: 100%; /* Fill the width of the container */
  max-width: 500px; /* Limit the width of the loader bar */
  background-color: #3498db; /* Color of the loading bar */
  animation: loading 2s ease-in-out infinite; /* Adjust animation duration as needed */
  border-radius: 2px; /* Rounded corners */
  box-shadow: 0 0 10px rgba(52, 152, 219, 0.8); /* Soft shadow */
}

@keyframes loading {
  0% {
    width: 0;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 100%;
  }
}

.blurred-content {
  filter: blur(4px); /* Adjust the blur amount as needed */
}


